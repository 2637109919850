import { Component, OnInit } from '@angular/core';
// import * as Potree from '../../../../build/potree/heli.js'
import * as Potree from '../../../assets/potre/build/potree/heli.js'
import { WindowRef } from 'src/app/heli-ai/nav/WindowRef.js';
// import * as THREE from "../../../../libs/three.js/build/three.module.js";
// import * as proj4 from '../../../../libs/proj4/proj4.js';

import * as $ from 'jquery';
import * as THREE from 'three';
import { MapService } from '../map.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-point-cloud',
  templateUrl: './point-cloud.component.html',
  styleUrls: ['./point-cloud.component.scss']
})
export class PointCloudComponent implements OnInit {
//   window:WindowRef
  viewer
  constructor(public mapService : MapService,private snackbar: MatSnackBar,) { }

  ngOnInit(): void {

	setTimeout(() => {
		this.loadPointCloud();
		// this.test();	
	}, 1000);
    
  }

  loadPointCloud(){
	console.log("sm1");
	
    this.viewer= new Potree.Viewer(
		document.getElementById("potree_render_area")
	);
    this.viewer.setEDLEnabled(false);
	this.viewer.setFOV(60);
	this.viewer.setPointBudget(1000000);
	this.viewer.loadSettingsFromURL();
    this.viewer.loadGUI(() => {
	$("#menu_tools").next().show();
	});

	this.test();
		// const elToolbar = $("#potree_toolbar");
		// elToolbar.html(`	
		// 	<div class="potree_toolbar_label" style="color:#000000";>
		// 		 <input type="file" id="myIng" />
		// 	</div>
		// 	`);
		// {
		// 	elToolbar.find("input[type=file]").click((e) => {
		// 		let fileName = e.target.files[0];
		// 		console.log(fileName);
		// 		$.getJSON(fileName.name, function (importedJson) {
		// 			let measure = new Potree.Measure();
		// 			console.log(importedJson);

		// 			for (var i = 0; i < importedJson.features.length; i++) {
		// 				switch (importedJson.features[i].geometry.type) {
		// 					case "Point":
		// 						// SINGLE POINT MEASURE
		// 						measure = new Potree.Measure();
		// 						measure.name = importedJson.features[i].properties.name;
		// 						measure.showDistances = false;
		// 						measure.showCoordinates = true;
		// 						measure.maxMarkers = 1;
		// 						measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

		// 						measure.addMarker(
		// 							new THREE.Vector3(
		// 								importedJson.features[i].geometry.coordinates[0],
		// 								importedJson.features[i].geometry.coordinates[1],
		// 								importedJson.features[i].geometry.coordinates[2]
		// 							)
		// 						);

		// 						this.viewer.scene.addMeasurement(measure);
		// 						break;
		// 					case "LineString":
		// 						// DISTANCE MEASURE
		// 						measure = new Potree.Measure();
		// 						measure.name = importedJson.features[i].properties.name;
		// 						measure.closed = false;
		// 						measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

		// 						for (
		// 							var j = 0;
		// 							j < importedJson.features[i].geometry.coordinates.length;
		// 							j++
		// 						) {
		// 							measure.addMarker(
		// 								new THREE.Vector3(
		// 									importedJson.features[i].geometry.coordinates[j][0],
		// 									importedJson.features[i].geometry.coordinates[j][1],
		// 									importedJson.features[i].geometry.coordinates[j][2]
		// 								)
		// 							);
		// 						}
		// 						this.viewer.scene.addMeasurement(measure);
		// 						break;
		// 					case "Polygon":
		// 						// ANGLE and AREA MEASURE
		// 						measure = new Potree.Measure();
		// 						measure.name = importedJson.features[i].properties.name;
		// 						if (measure.name == "Angle") {
		// 							measure.showDistances = false;
		// 							measure.showAngles = true;
		// 							measure.showArea = false;
		// 						} else if (measure.name == "Area") {
		// 							measure.showDistances = true;
		// 							measure.showArea = true;
		// 							measure.showAngles = false;
		// 						}
		// 						measure.closed = true;
		// 						measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

		// 						for (
		// 							var j = 0;
		// 							j <
		// 							importedJson.features[i].geometry.coordinates[0].length - 1;
		// 							j++
		// 						) {
		// 							measure.addMarker(
		// 								new THREE.Vector3(
		// 									parseFloat(
		// 										importedJson.features[i].geometry.coordinates[0][j][0]
		// 									),
		// 									parseFloat(
		// 										importedJson.features[i].geometry.coordinates[0][j][1]
		// 									),
		// 									parseFloat(
		// 										importedJson.features[i].geometry.coordinates[0][j][2]
		// 									)
		// 								)
		// 							);
		// 						}

		// 						this.viewer.scene.addMeasurement(measure);
		// 						break;
		// 					default:
		// 						console.info("There aren't any measurements to be uploaded");
		// 						return false;
		// 				}
		// 			}
		// 		});

		// 		//   viewer.scene.pointclouds.forEach(
		// 		//     (pc) => (pc.material.activeAttributeName = "elevation")
		// 		//   );
		// 	});

		// 	elToolbar.find("img[name=action_rgb]").click(() => {
		// 		this.viewer.scene.pointclouds.forEach(
		// 			(pc) => (pc.material.activeAttributeName = "rgba")
		// 		);
		// 	});
		// }

		// {
		// 	const schemes = Object.keys(Potree.Gradients).map((name) => ({
		// 		name: name,
		// 		values: Potree.Gradients[name],
		// 	}));
		// 	const elGradientSchemes = elToolbar.find("span[name=gradient_schemes]");

		// 	for (const scheme of schemes) {
		// 		const elButton = $(`
		// 		<span style=""></span>
		// 	`);

		// 		const svg = Potree.Utils.createSvgGradient(scheme.values);
		// 		svg.setAttributeNS(null, "class", `button-icon`);
		// 		svg.style.height = "2em";
		// 		svg.style.width = "1.3em";

		// 		elButton.append($(svg));

		// 		elButton.click(() => {
		// 			for (const pointcloud of this.viewer.scene.pointclouds) {
		// 				pointcloud.material.activeAttributeName = "elevation";
		// 				pointcloud.material.gradient = Potree.Gradients[scheme.name];
		// 			}
		// 		});

		// 		elGradientSchemes.append(elButton);
		// 	}
		// }

		// {
		// 	elToolbar.find("img[name=action_measure_point]").click(() => {
		// 		const measurement = this.viewer.measuringTool.startInsertion({
		// 			showDistances: false,
		// 			showAngles: false,
		// 			showCoordinates: true,
		// 			showArea: false,
		// 			closed: true,
		// 			maxMarkers: 1,
		// 			name: "Point",
		// 		});
		// 	});

		// 	elToolbar.find("img[name=action_measure_distance]").click(() => {
		// 		const measurement = this.viewer.measuringTool.startInsertion({
		// 			showDistances: true,
		// 			showArea: false,
		// 			closed: false,
		// 			name: "Distance",
		// 		});
		// 	});

		// 	elToolbar.find("img[name=action_measure_circle]").click(() => {
		// 		const measurement = this.viewer.measuringTool.startInsertion({
		// 			showDistances: true,
		// 			showArea: true,
		// 			closed: true,
		// 			name: "Area",
		// 		});
		// 	});
		// }

		// {
		// 	let options = ["rgba", "elevation"];

		// 	let attributeSelection = elToolbar.find("#optMaterial");
		// 	for (let option of options) {
		// 		let elOption = $(`<option>${option}</option>`);
		// 		attributeSelection.append(elOption);
		// 	}

		// 	const updateMaterialSelection = (event, ui) => {
		// 		let selectedValue = attributeSelection.selectmenu().val();

		// 		for (const pointcloud of this.viewer.scene.pointclouds) {
		// 			pointcloud.material.activeAttributeName = selectedValue;
		// 		}
		// 	};
		// 	console.log(attributeSelection)
		// 	attributeSelection.selectmenu({ change: updateMaterialSelection });
		// }

		// {
		// 	elToolbar.find("#sldPointBudget").slider({
		// 		value: this.viewer.getPointBudget(),
		// 		min: 100000,
		// 		max: 10000000,
		// 		step: 100000,
		// 		slide: (event, ui) => {
		// 			this.viewer.setPointBudget(ui.value);
		// 		},
		// 	});

		// 	const onBudgetChange = () => {
		// 		let budget = (this.viewer.getPointBudget() / 1000000).toFixed(1) + "M";
		// 		elToolbar.find("span[name=lblPointBudget]").html(budget);
		// 	};

		// 	onBudgetChange();
		// 	this.viewer.addEventListener("point_budget_changed", onBudgetChange);
		// }
  }

  test(){
    console.log(this.viewer)
	console.log("sm2");
    let str = $("#myInput").val();
		let		str1 = $("#myIn").val();
		let		url = this.mapService.pointCloudUrl;
		let		name = str1;
		console.log(this.mapService.pointCloudUrl,'pointcloudurl')
		if(this.mapService.isType == 'Point Cloud'){
			// function
			this.loadOldData()
		}
		Potree.loadPointCloud(url + `.json`, name, (e) => {
					console.log(e, "---------------------------------->PointCloud");
					const scene = this.viewer.scene;
					const pointcloud = e.pointcloud;

					const material = pointcloud.material;
					material.size = 1;
					material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
					material.shape = Potree.PointShape.SQUARE;
					scene.addPointCloud(pointcloud);
					scene.view.setView(
						[590144.208, 231895.191, 1077.02],
						[589800.0, 231432.852, 756.242]
					);
					//  e.pointcloud.position.z -= 45;
					e.pointcloud.castShadow = true;
					this.viewer.fitToScreen();

					var awsurl;
					$('input[type="file"]').change(function (e) {
						let fileName = e.target.files[0];
						// alert("A file has been selected.");
						const formData = new FormData();
						formData.append("file", fileName);
						//   console.log(formData)
						$.ajax({
							url: "http://143.110.180.66/geo_upload/",
							type: "POST",
							data: formData,
							processData: false,
							contentType: false,
							success: function (da) {
								awsurl = da;
								// console.log(awsurl)

								$.getJSON(awsurl, function (importedJson) {
									let measure = new Potree.Measure();
									console.log(importedJson);

									for (var i = 0; i < importedJson.features.length; i++) {
										switch (importedJson.features[i].geometry.type) {
											case "Point":
												// SINGLE POINT MEASURE
												measure = new Potree.Measure();
												measure.name =
													importedJson.features[i].properties.name;
												measure.showDistances = false;
												measure.showCoordinates = true;
												measure.maxMarkers = 1;
												measure.lengthUnitDisplay =this.viewer.lengthUnitDisplay;

												measure.addMarker(
													new THREE.Vector3(
														importedJson.features[i].geometry.coordinates[0],
														importedJson.features[i].geometry.coordinates[1],
														importedJson.features[i].geometry.coordinates[2]
													)
												);

												this.viewer.scene.addMeasurement(measure);
												break;
											case "LineString":
												// DISTANCE MEASURE
												measure = new Potree.Measure();
												measure.name =
													importedJson.features[i].properties.name;
												measure.closed = false;
												measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

												for (
													var j = 0;
													j <
													importedJson.features[i].geometry.coordinates
														.length;
													j++
												) {
													measure.addMarker(
														new THREE.Vector3(
															importedJson.features[i].geometry.coordinates[
															j
															][0],
															importedJson.features[i].geometry.coordinates[
															j
															][1],
															importedJson.features[i].geometry.coordinates[
															j
															][2]
														)
													);
												}
												this.viewer.scene.addMeasurement(measure);
												break;
											case "Polygon":
												// ANGLE and AREA MEASURE
												measure = new Potree.Measure();
												measure.name =
													importedJson.features[i].properties.name;
												if (measure.name == "Angle") {
													measure.showDistances = false;
													measure.showAngles = false;
													measure.showArea = false;
												} else if (measure.name == "Area") {
													measure.color.r = 0;
													measure.color.b = 1;
													measure.showDistances = false;
													measure.showArea = true;
													measure.showAngles = false;
													measure.showCoordinates = false;
													// console.log(measure)
												}
												measure.closed = true;
												measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

												for (
													var j = 0;
													j <
													importedJson.features[i].geometry.coordinates[0]
														.length -
													1;
													j++
												) {
													measure.addMarker(
														new THREE.Vector3(
															parseFloat(
																importedJson.features[i].geometry
																	.coordinates[0][j][0]
															),
															parseFloat(
																importedJson.features[i].geometry
																	.coordinates[0][j][1]
															),
															parseFloat(
																importedJson.features[i].geometry
																	.coordinates[0][j][2]
															)
														)
													);
												}

												this.viewer.scene.addMeasurement(measure);
												break;
											default:
												console.info(
													"There aren't any measurements to be uploaded"
												);
												return false;
										}
									}
								});
							},
						});
					});
					// console.log(awsurl,"-------------------AWSURL")

					// let Geourl = "resources/measure.json";
					// var fileName=[];
					//     $('input[type="file"]').change(function (e) {
					//   fileName = e.target.files[0];
					//   console.log(fileName)
					//  console.log(fileName)
					// 	const formData = new FormData();
					//   formData.append("zip_file", fileName);
					//   console.log(formData)
					// setTimeout(function(){

					// },5000)
				});
  }

  loadOldData(){
    Potree.loadProject(this.viewer,this.mapService.oldData)     
	}

  toggle2Dswitch(){
	this.mapService.toggle3Dswitch = false
  }

  savePointCloudData(){
	console.log("this.mapService.oldData",this.mapService.selectedData);
	
	let data = Potree.saveProject(this.viewer)
	let pointTemp = data['pointclouds'][0];
	data['pointclouds'] = [];
	data['pointclouds'].push(pointTemp);
	let data2 = {
	  data : data,
	  projectId : this.mapService.selectedData.projectId,
	  layereId : this.mapService.selectedData.item._id,
	  layereName : this.mapService.selectedData.item.layerName,
	}
  
	console.log("data2",data2);
	
	this.mapService.updatePointCloudFinalData(data2).subscribe((res)=>{
	  console.log(res)
	  // if(res['message'] === "Updated"){
		this.snackbar.open(res['message'], 'ok')
	  // }
	  })
  }
  

}
